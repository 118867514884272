document.addEventListener('turbo:frame-missing', function (event) {
  const frameId = event.target.id;
  const response = event.detail.response;
  const message = `Turbo frame ${frameId} missing from response ${response.url} (${response.status} ${response.statusText}). Add data: { turbo: false } to the link or add the missing frame to the response.`;
  const error = new Error(message);

  if (typeof Honeybadger !== 'undefined' && response.status == 200) {
    Honeybadger.notify(error);
  }

  console.error(error);

  event.preventDefault();
  event.detail.visit(event.detail.response);
});
