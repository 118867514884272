import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dismissButton', 'dismissContainer', 'errorField'];

  connect() {
    $(this.dismissButtonTarget).on('click', this.removeError);
  }

  disconnect() {
    $(this.dismissButtonTarget).off('click', this.removeError);
  }

  removeError = (event) => {
    this.dismissContainerTarget.remove();
    this.errorFieldTarget.setAttribute('value', '');
    // We may want to auto-submit only this property at a later point
  };
}
