import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['removeButton'];

  connect() {
    $(this.removeButtonTarget).on('click', this.removeElement);
  }

  disconnect() {
    $(this.removeButtonTarget).off('click', this.removeElement);
  }

  removeElement = (event) => {
    this.element.remove();
  };
}
