import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['editor'];

  get locale() {
    return this.editorTarget.dataset.locale;
  }

  snippet(variableName) {
    return '{{ ' + variableName + ' }}';
  }

  injectVariable(e) {
    const selectedVariable = e.target.value;

    if (selectedVariable === '') {
      return;
    }

    this.editorTarget.editor.recordUndoEntry('Insert Tag');
    this.editorTarget.editor.insertString(this.snippet(selectedVariable));

    e.target.value = '';
  }
}
